// Await/async imports
import 'core-js/stable';
import 'regenerator-runtime/runtime';

// Sentry
import * as Sentry from '@sentry/browser';

// Only bind when production mode is set
if (window.SENTRY_DSN) {
  console.log('Setting up default Sentry client');

  Sentry.init({
    dsn: window.SENTRY_DSN,
    tunnel: '/_stun',
    release: window.SENTRY_RELEASE,
  });

  Sentry.setUser({
    username: window.SENTRY_USERNAME,
    ip_address: window.SENTRY_IP_ADDRESS,
  });
}

// Import select2
import 'select2';

// Collection form support
import 'script-loader!symfony-collection/jquery.collection';

// Import application fixes
import {Fixes} from '@drenso/vue-frontend-shared';

Fixes.Select2.jQuery3dot6Fix();

/**
 * Array move function
 * @param from
 * @param to
 */
Array.prototype.move = function(from: number, to: number) {
  this.splice(to, 0, this.splice(from, 1)[0]);
  return this;
};

$(() => {
  // Bootstrap file input helper plugin
  if ($('.custom-file').length > 0) {
    import(/* webpackChunkName: "bs-custom-file-input" */ 'bs-custom-file-input')
        .then((bsCustomFileInput) => bsCustomFileInput.default.init());
  }

  // Tooltips
  $('[data-toggle="tooltip"]').each(function() {
    // Call tooltip per element to prevent issues with Vue components
    $(this).tooltip({
        boundary: 'viewport',
        container: 'body',
    });
  });

  // Side menu collapsed state
  $(document)
    .on('shown.lte.pushmenu', () => {
      document.cookie = 'SIDEBAR_COLLAPSED=false';
    })
    .on('collapsed.lte.pushmenu', () => {
      document.cookie = 'SIDEBAR_COLLAPSED=true';
    });
});

declare global {
  interface Array<T> {
    move: (from: number, to: number) => T[];
  }
}
